import httpUtil from "@/utils/httpUtil";

/**获取需求列表 */
export const queryPageMyDemandSquare = (params) =>
  httpUtil.post("/api/crmPc/demandSquare/queryPageMyDemandSquare", params);

/**需求分类 */
export const queryAllClassification = (params) =>
  httpUtil.post("/api/crmPc/demandSquare/queryAllClassification", params);

/**新增需求 */
export const addDemandSquare = (params, type) => httpUtil.post("/api/crmPc/demandSquare/addDemandSquare", params, type);

/**获取编辑详情 */
export const queryDemandSquare = (params, type) =>
  httpUtil.post("/api/crmPc/demandSquare/queryDemandSquare", params, type);

/**保存编辑 */
export const updateDemandSquare = (params, type) =>
  httpUtil.post("/api/crmPc/demandSquare/updateDemandSquare", params, type);

/**删除 */
export const deleteDemandSquare = (params) => httpUtil.post("/api/crmPc/demandSquare/deleteDemandSquare", params);

/**撤回 or 上线 */
export const updateDemandSquareStatus = (params) =>
  httpUtil.post("/api/crmPc/demandSquare/updateDemandSquareStatus", params);

/**提交审核 */
export const commitAuditing = (params) => httpUtil.post("/api/crmPc/demandSquare/commitDemandSquare", params);

/**获取海报信息 */
export const queryPagePoster = (params) => httpUtil.post("/api/crmPc/poster/queryPagePoster", params);

/**删除海报 */
export const deletePoster = (params) => httpUtil.post("/api/crmPc/poster/deletePoster", params);

/**新增海报模块 */
export const addPoster = (params) => httpUtil.post("/api/crmPc/poster/addPoster", params);

/**编辑海报模块 */
export const updatePoster = (params) => httpUtil.post("/api/crmPc/poster/updatePoster", params);

/**获取打卡数据 */
export const queryPageUserClock = (params) => httpUtil.post("/api/crmPc/poster/queryPageUserClock", params);

/**获取打卡详情数据 */
export const queryPageUserClockDetail = (params) => httpUtil.post("/api/crmPc/poster/queryPageUserClockDetail", params);

/**需求审核 */
export const queryPageDemandSquare = (params) => httpUtil.post("/api/crmPc/demandSquare/queryPageDemandSquare", params);

/**通过审核 or 驳回*/
export const reviewDemandSquareStatus = (params) =>
  httpUtil.post("/api/crmPc/demandSquare/reviewDemandSquareStatus", params);

/**批量通过 */
export const batchReviewDemandSquareStatus = (params) =>
  httpUtil.post("/api/crmPc/demandSquare/batchReviewDemandSquareStatus", params);

/**人脉集市审核 */
export const queryVerifyPageBusinessCard = (params) =>
  httpUtil.post("/api/crmPc/demandSquare/queryVerifyPageBusinessCard", params);

/** 人脉集市通过*/
export const verifyBusinessCard = (params) => httpUtil.post("/api/crmPc/demandSquare/verifyBusinessCard", params);

/**人脉集市批量通过 */
export const batchVerifyBusinessCard = (params) =>
  httpUtil.post("/api/crmPc/demandSquare/batchVerifyBusinessCard", params);

/** 人脉集市删除*/
export const offBusinessCard = (params) => httpUtil.post("/api/crmPc/demandSquare/offBusinessCard", params);

/**产品中心审核 */
export const queryGoodVerify = (params) => httpUtil.post("/api/crmPc/demandSquare/queryGoodVerify", params);

/** 人脉集市通过*/
export const goodVerify = (params) => httpUtil.post("/api/crmPc/demandSquare/goodVerify", params);

/**产品中心审核批量通过 */
export const offGoodVerify = (params) => httpUtil.post("/api/crmPc/demandSquare/offGoodVerify", params);

/**发布设置获取 */
export const queryPublishSetting = (params, type) =>
  httpUtil.post("/api/crmPc/demandSquare/queryPublishSetting", params, type);
/**保存发布设置 */
export const updatePublishSetting = (params) => httpUtil.post("/api/crmPc/demandSquare/updatePublishSetting", params);

/**举报处理 */
export const queryReport = (params) => httpUtil.post("/api/crmPc/demandSquare/queryReport", params);

/**分类管理*/
export const queryPageClassification = (params) =>
  httpUtil.post("/api/crmPc/demandSquare/queryPageClassification", params);

/**分类管理状态设置 */
export const updateClassificationStatus = (params) =>
  httpUtil.post("/api/crmPc/demandSquare/updateClassificationStatus", params);
/**删除 */
export const deleteClassificationStatus = (params) =>
  httpUtil.post("/api/crmPc/demandSquare/deleteClassificationStatus", params);

/**添加分类 */
export const addClassification = (params) => httpUtil.post("/api/crmPc/demandSquare/addClassification", params);

/**修改分类 */
export const updateClassification = (params) => httpUtil.post("/api/crmPc/demandSquare/updateClassification", params);

/**保存自定义设置 */

export const updateCustomizeSetting = (params) =>
  httpUtil.post("/api/crmPc/demandSquare/updateCustomizeSetting", params);

/**获取自定义设置 */
export const queryCustomizeSetting = (params, type) =>
  httpUtil.post("/api/crmPc/demandSquare/queryCustomizeSetting", params, type);

/**获取自定义设置 */
export const queryPoster = (params, type) => httpUtil.post("/api/crmPc/poster/queryPoster", params, type);

/**产品中心批量操作 */
export const batchGoodVerify = (params) => httpUtil.post("/api/crmPc/demandSquare/batchGoodVerify", params);

/**海报删除 */
export const deletePosterByPosterId = (params) => httpUtil.post("/api/crmPc/poster/deletePosterByPosterId", params);
